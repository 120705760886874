import { ICategory, ICategoryGroup } from "~/types/page-service";

const getCategoriesByGroupLevel1 = (
  categoryList: Array<ICategory>
): Array<ICategoryGroup> => {
  const categoriesByGroup = [] as Array<ICategoryGroup>;
  categoryList.forEach((category: ICategory) => {
    if (category.order) {
      const categoryGroup: ICategoryGroup = {
        ...category,
        items: []
      };
      categoryGroup.items = [];
      categoriesByGroup.push(categoryGroup);
    }
  });
  categoriesByGroup.sort((a, b) => {
    if (a.order && b.order) {
      return a.order - b.order;
    } else if (a.order) {
      return 1;
    } else if (b.order) {
      return -1;
    }
    return 0;
  });
  categoriesByGroup.forEach((categoryGroup: ICategoryGroup) => {
    categoryList.forEach((category: ICategory) => {
      if (!category.parent) return;
      if (category.parent.slug === categoryGroup.slug) {
        categoryGroup.items.push(category);
      }
    });
  });
  return categoriesByGroup;
};

const getCategoriesByGroupLevel2 = (
  categoryList: Array<ICategory>,
  categoryGroupsLevel1: Array<ICategoryGroup>
): Array<ICategoryGroup> => {
  const categoriesByGroup = [] as Array<ICategoryGroup>;
  categoryList.forEach((category: ICategory) => {
    if (!category.parent && !category.isEligibleForPosts && !category.order) {
      const categoryGroup: ICategoryGroup = {
        ...category,
        items: []
      };
      categoryGroup.items = [];
      categoryGroupsLevel1.forEach((group) => {
        if (!group.parent) return;
        if (group.parent.name === category.name) {
          categoryGroup.items.push(...group.items);
        }
      });
      categoriesByGroup.push(categoryGroup);
    }
  });
  return categoriesByGroup;
};

const getCategoryListFilter = (
  categoryList: Array<ICategory>,
  slug: string
): Array<string> => {
  const categoriesLevel1 = getCategoriesByGroupLevel1(categoryList);
  const categoriesLevel2 = getCategoriesByGroupLevel2(
    categoryList,
    categoriesLevel1
  );

  const indexGroupLevel2 = categoriesLevel2.map((g) => g.slug).indexOf(slug);
  if (indexGroupLevel2 > -1) {
    return [
      ...(categoriesLevel2[indexGroupLevel2] as ICategoryGroup).items
    ].map((c) => c.slug);
  }
  const indexGroupLevel1 = categoriesLevel1.map((g) => g.slug).indexOf(slug);
  if (indexGroupLevel1 > -1) {
    return [...categoriesLevel1[indexGroupLevel1].items].map((c) => c.slug);
  }
  const indexCategory = categoryList.map((g) => g.slug).indexOf(slug);
  if (indexCategory > -1) {
    return [categoryList[indexCategory]].map((c) => c.slug);
  }
  return [];
};

const getCategoriesEligibleForPosts = (
  categoryList: Array<ICategory>
): Array<ICategory> => {
  const categories = categoryList.filter(
    (category: ICategory) => category.isEligibleForPosts === true
  );
  return categories;
};

export {
  getCategoriesByGroupLevel1,
  getCategoriesByGroupLevel2,
  getCategoryListFilter,
  getCategoriesEligibleForPosts
};
